import type { Theme } from "./Board";

export const getFontSheetUrl = (font: string) => {
  return `https://fonts.googleapis.com/css2?family=${font.replace(
    /\s/g,
    "+",
  )}:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap`;
};

export interface ThemePreset extends Omit<Theme, "basedOn"> {}

export const PLACEHOLDER_COLORS_LIGHT = [
  "#FFCDD2",
  "#F8BBD0",
  "#E1BEE7",
  "#D1C4E9",
  "#C5CAE9",
  "#BBDEFB",
  "#B3E5FC",
  "#B2EBF2",
  "#B2DFDB",
  "#C8E6C9",
  "#DCEDC8",
  "#F0F4C3",
  "#FFF9C4",
  "#FFECB3",
  "#FFE0B2",
];

export const PLACEHOLDER_COLORS = [
  "#EF5350",
  "#EC407A",
  "#AB47BC",
  "#7E57C2",
  "#5C6BC0",
  "#42A5F5",
  "#29B6F6",
  "#26C6DA",
  "#26A69A",
  "#66BB6A",
  "#9CCC65",
  "#D4E157",
  "#FFEE58",
  "#FFCA28",
  "#FFA726",
];

export const THEME_PRESETS: ThemePreset[] = [
  {
    id: "garden",
    name: "Garden",
    primaryColor: "#7BCE3B",
    secondaryColor: "#8CE3B0",
    accentColor: "#68D9C6",
    darkColor: "#0F1D07",
    backgroundColor: "#F8FDF7",
    fontBody: {
      family: "Roboto",
      weight: "400",
    },
    fontHeading: {
      family: "Roboto",
      weight: "500",
    },
    lightColor: "#F8FDF7",
    borderRadius: "none",
  },
  {
    id: "funk",
    name: "Funk",
    primaryColor: "#DF2C82",
    secondaryColor: "#EED58F",
    accentColor: "#D7E552",
    darkColor: "#110308",
    lightColor: "#FEF6F9",
    backgroundColor: "#FEF6F9",
    fontBody: {
      family: "Roboto",
      weight: "400",
    },
    fontHeading: {
      family: "Roboto",
      weight: "500",
    },
  },
  {
    id: "nightlife",
    name: "Nightlife",
    lightColor: "#DAD2FC",
    backgroundColor: "#09021C",
    darkColor: "#09021C",
    primaryColor: "#9471F7",
    secondaryColor: "#9E0915",
    accentColor: "#F38C37",
    fontBody: {
      family: "Roboto",
      weight: "400",
    },
    fontHeading: {
      family: "Roboto",
      weight: "500",
    },
  },
  {
    id: "bubblegum",
    name: "Bubblegum",
    fontBody: {
      family: "Baloo Chettan 2",
      weight: "400",
    },
    fontHeading: {
      family: "Baloo Chettan 2",
      weight: "700",
    },
    primaryColor: "#5725F1",
    secondaryColor: "#F67BDB",
    accentColor: "#F45390",
    darkColor: "#05010F",
    lightColor: "#F4F1FE",
    backgroundColor: "#F4F1FE",
  },
  {
    id: "plastic",
    name: "Plastic",
    primaryColor: "#1640FE",
    secondaryColor: "#FFB57C",
    accentColor: "#ECFE49",
    darkColor: "#0A001A",
    backgroundColor: "#FBF9FF",
    lightColor: "#FBF9FF",
    fontBody: {
      family: "Roboto",
      weight: "400",
    },
    fontHeading: {
      family: "Roboto",
      weight: "500",
    },
  },
  {
    id: "lavender",
    name: "Lavender",
    primaryColor: "#C13E7B",
    secondaryColor: "#DF9BBC",
    accentColor: "#D06A8A",
    backgroundColor: "#F9F4FC",
    darkColor: "#0D0510",
    fontBody: {
      family: "Roboto",
      weight: "400",
    },
    fontHeading: {
      family: "Roboto",
      weight: "500",
    },
  },
  {
    id: "gizmo",
    name: "Gizmo",
    lightColor: "#F9F9F9",
    backgroundColor: "#02090B",
    darkColor: "#02090B",
    primaryColor: "#E17439",
    secondaryColor: "#981744",
    accentColor: "#8FD6EE",
    borderRadius: "none",
    fontBody: {
      family: "Roboto",
      weight: "400",
    },
    fontHeading: {
      family: "Roboto",
      weight: "500",
    },
  },
];

type NonNullableObject<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export const APP_THEME: NonNullableObject<Theme> = {
  id: "app",
  name: "App",
  primaryColor: "#00CC99",
  secondaryColor: "#FFEE88",
  accentColor: "#DD1155",
  darkColor: "#0A1C0D",
  lightColor: "rgba(255,255,255,0.9)",
  backgroundColor: "#fff",
  dangerColor: "#f44336",
  warningColor: "#ffa726",
  successColor: "#66bb6a",
  fontBody: {
    family: "DM Sans",
    weight: "400",
  },
  fontHeading: {
    family: "Outfit",
    weight: "500",
  },
  borderRadius: "small",
};

export const LINK_COLOR = "#6955FF";
export const DRAWING_COLOR = "#38302E";
export const CONTENT_COLOR = "#FFA500";
export const SOCIAL_COLOR = "#1DB954";
export const MEDIA_COLOR = "#00A6ED";
export const AMA_COLOR = "#BA324F";
export const IMAGE_COLOR = "#00A6ED";
export const PROFILE_PICTURE_COLOR = "#F4E04D";
